/* ------------------------------------------ Functions ------------------------------ */
//version: _ToggleBoxes.js v.1.0.7

// sets show/hide based on arguments
function ifCheckedHide(idCheckedInput, idHideShow) {
	if ($( "#" + idCheckedInput ).is(":checked")) $( "#" + idHideShow ).hide();
	else $( "#" + idHideShow ).show();
}


let comboBoxes = [];	// to be used to fill with each dropdown item
let previousSelectedCustomer;
let previousSelectedProject;
let invalidProject;		// tom-select option object for invalid/archived project
let listenersNames = [] //an array to prevent duplicate listeners.

/**
 * Removes focus from the currently active form element.
 *
 * This function uses `document.activeElement` to identify the currently
 * focused element and calls `blur()` on it to remove focus.
 * Useful for programmatically unfocusing input fields, buttons, or other elements.
 */
let autoBlur = null;
function removeActiveElementFocus() {
	console.info("Starting auto blur timer.")
	autoBlur = setTimeout(()=>{
		document.activeElement.blur();
		//console.info("listenersNames: ",listenersNames)
		//console.info("Form Blurrrrred.");
	},1000)
}

function clearAutoBlur (){
	clearTimeout(autoBlur);
	//console.warn("Auto Blur Cancelled.");
}
/**
 * Finds and returns the closest parent `<form>` element of the given element.
 *
 * This function traverses up the DOM tree from the provided element until it
 * finds a `<form>` element or reaches the `<body>`. If no `<form>` is found,
 * it logs a warning and returns `null`.
 *
 * @param {HTMLElement} element - The starting element to search from.
 * @returns {HTMLFormElement | null} - The closest parent `<form>` element, or `null` if not found.
 */
function getParentForm(element) {
	while (element && element !== document.body) {
			if (element.tagName === 'FORM' || element.id ==='contentPad') {
					return element;
			}
			element = element.parentElement;
	}
	//bugsnag:
	Bugsnag.notify("_ToggleBoxes.js: No parent <form> element found.");
	return null; // Return null if no parent form is found
}
/**
 * Prevents accidental selection of the last selected form element on window mouseleave and reenter.
 *
 */
function autoBlurOnElement(element){
	if(listenersNames.includes("body")){
		return; //do nothing.
	}

	try{
		element.addEventListener("mouseleave", function () {
			removeActiveElementFocus();
		});

		element.addEventListener("mouseenter", function () {
			clearAutoBlur();
		});
		//if the listener was successfully applied, log that form name.
		listenersNames.push("body");
	}
	catch(err){
		//do nothing
	}
}
/**
 * Searchable combobox setup (called by document ready), finds "searchable-input" and creates a searchable dropdown with Tom Select
 *
 */
function initComboBox() {
	//autoBlurOnElement();
	document.querySelectorAll('.searchable-input').forEach((element)=>{
		//When a searchable box if found, find the parent form tag.
		//console.info("Searchable Select Box Found: ",element);
		//const parentForm = getParentForm(element)
		//console.info("Parent Form: ",parentForm.name);

		//add a listener to remove focus on any form element when moused out of the form.
		autoBlurOnElement(document.body);

		// If we already have List Items we need to remove them from the list of comboBoxes and add again
		if(element.id == "CustomerID"){
			previousSelectedCustomer = $("#CustomerID :selected").val();
			if(comboBoxes.find(listItem => listItem.id == "CustomerID")){
				comboBoxes = comboBoxes.filter(function(item){return item.id != "CustomerID"});
			}
		}
		if(element.id == "ProjectID"){
			previousSelectedProject = $("#ProjectID :selected").val();
			if(comboBoxes.find(listItem => listItem.id == "ProjectID")){
				comboBoxes = comboBoxes.filter(function(item){return item.id != "ProjectID"});
			}
		}
		if(comboBoxes.find(listItem => listItem.id == "AccountCodeID")){
			comboBoxes = comboBoxes.filter(function(item){return item.id != "AccountCodeID"});
		}
		if(comboBoxes.find(listItem => listItem.id == "EventID")){
			comboBoxes = comboBoxes.filter(function(item){return item.id != "EventID"});
		}
		if(comboBoxes.find(listItem => listItem.id == "VendorID")){
			comboBoxes = comboBoxes.filter(function(item){return item.id != "VendorID"});
		}

		this.selectedItem = "";	// item selected when the page loads, why didn't tom-select include this?
		let settings = {
			maxOptions: null,
			maxItems: 1,
			closeAfterSelect: true,
			allowEmptyOption: true,
			score: function(search) {
				search = search.toLowerCase().trim(); // Normalize search term
				if(search.length === 1){
					//return first character match only
					return function(item) {
            let words = item.text.toLowerCase().split(/\s+/);
            let initials = words.map(word => word.charAt(0)).join(''); // Get initials
            return initials.startsWith(search) ? 1 : 0; // Match only first letters
        	};
				}else{
					//return option with a space saerch
					return function(item) {
							let text = item.text.toLowerCase();
							// Check if search term (including spaces) appears anywhere in the text
							return text.includes(search) ? 1 : 0;
					};
				}
    	},
			plugins: ['dropdown_input'],
			onChange: function(){
				// for Customer and Project only
				if(element.id == "CustomerID" || element.id == "ProjectID"){

					if(element.id == "ProjectID" && this.options && this.options[this.items[0]] && this.options[this.items[0]].description){
						setProjectDescription(decodeURIComponent(this.options[this.items[0]].description));
					}
					else {
						setProjectDescription("");
					}

					// we need to set these static values for changeBillRate() to function properly
					if(element.id == "ProjectID"){
						invalidProject = this.options[$("#ProjectID_invalidOption").val()];

						if(this.options && this.options[this.items[0]]){
							$("#currentBillRate").val(this.options[this.items[0]].billrate);
							$("#ProjectID").val(this.options[this.items[0]].value);
							changeBillRate();	// lives in page-project-edit.js
						}
					}
				}

				if($("#"+element.id+"_invalidOption").val()){
					if(this.options[this.items[0]] && (this.options[this.items[0]].value == $("#"+element.id+"_invalidOption").val())){
						$("#"+element.id+"_invalid").show();
					}
					else {
						$("#"+element.id+"_invalid").hide();
					}
				}
			},
			onBlur: function(){
				if(this.items.length < 1){
					// if the user leaves the search blank, set item selected when the page loaded
					let selected = this.options[this.selectedItem];
					if(selected){
						this.setValue(selected.value, false);
					}
				}
			},
			onInitialize: function(){
				this.selectedItem = this.items[0];
			}
		};

		let hasDropdown = comboBoxes.find(tomItem => tomItem.id == element.id);
		// make sure the dropdown isn't already on the page. All dropdowns must have an id or name attribute!
		if((element.id || element.name) && !hasDropdown){
			let tSelect = new TomSelect(element,settings);
			comboBoxes.push({ id: element.id, tomList: tSelect });
		}
	});
}

/**
 *	Update a given combobox based on previously selected item (so far just Customer -> Project)
 *
 * 	@param json {Object} - list/array of select items to add to an existing combobox
 * 	@param previousSelectedID {String} - ID of item (Project only for now) that was selected before getting new item list
 *	@param id {String} - we don't need this much, but can use it if we go beyond just customer/project
 */
function updateComboBox(json, previousSelectedValue, id){
	comboBoxes.forEach(function(item, index){
		// So far just "ProjectID"
		let previousSelectedInArray = json.filter((json) => json.PROJECTID == previousSelectedValue);	// see if the previous Project is in the new list

		if(previousSelectedInArray[0] && previousSelectedInArray[0].PROJECTDESCRIPTION){
			setProjectDescription(previousSelectedInArray[0].PROJECTDESCRIPTION);
		}
		if(id == item.id){
			if(json.length > 0){
				if(previousSelectedInArray.length <= 0){
					item.tomList.clear(false);		// clears selected items, must be called before clearOptions() for some reason
				}
				item.tomList.clearOptions();	// if the new list has anything, clean the old list out
			}

			// loop through the new options and add to the list
			json.forEach(function(jsonItem, jsonIndex){
				let projectDescription = (jsonItem.PROJECTDESCRIPTION != "") ? jsonItem.PROJECTDESCRIPTION : getTranslation("Content.Label.None");
				let option = { value: jsonItem.PROJECTID, text: jsonItem.PROJECTNAME, description: projectDescription, minuteinc: jsonItem.MINIMUMTIMEINCREMENT, billrate: jsonItem.DEFAULTUSERBILLRATE };
				item.tomList.addOption(option);
			});


			if(invalidProject){
				// make sure to add the invalid project back into the list
				item.tomList.addOption(invalidProject);

				if(previousSelectedInArray.length == 0){
					item.tomList.setValue(invalidProject.value, false);
				}
			}
			else if(previousSelectedInArray.length <= 0){
				// if the previous item is not in the new list, fall back to the first item, usually Unassigned/default
				let firstOption = Object.values(item.tomList.options)[0].value;
				item.tomList.setValue(firstOption, false);
			}
		}
	});
}

/**
 * Set the project description field, including the small dialog box when needed
 * @param {String} description
 */
function setProjectDescription(description){
	$("#inlineProjectDescription").remove();
	$("#projectDescription").empty();
	$("#projectDialogLabelContainer").remove();

	if(description == ""){
		$(".projectDesc").append("<div id='inlineProjectDescription'>" + getTranslation("Content.Label.None") + "</div>");
	}
	else {
		$("#projectDescription").html("<p>" + description + "</p>");
		$(".projectDesc").append("<div id='inlineProjectDescription'>" + description + "</div>");	// short description

		if(description.length > 30){	// add "More..." and the little pop-up with full description for longer text
			$(".projectDesc").append("<div id='projectDialogLabelContainer'><a href='javascript:launchProjectDialog()' id='projectDialogLabel'>" + getTranslation("JavaScript.Link.MoreEllipsis") + "</a></div>");
		}
	}
}
